var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$appConfig.brand && _vm.$appConfig.brand.features.sharing && _vm.$appConfig.brand.features.sharing.enabled ? _c('v-card', {
    staticClass: "px-5 ml-sm-3 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('yourSharedPartsOrders')) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "loading": _vm.loading || _vm.loading2
    },
    on: {
      "click": _vm.refresh
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1), _c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": "",
      "disabled": _vm.loading
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.orders,
      "search": _vm.search,
      "loading": _vm.loading,
      "options": {
        sortBy: ['created'],
        sortDesc: [true]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.created`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")];
      }
    }, {
      key: `item.status`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")];
      }
    }], null, true)
  })], 1), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('currentSharedPartsList')))]), _c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": "",
      "disabled": _vm.loading2
    },
    model: {
      value: _vm.search2,
      callback: function ($$v) {
        _vm.search2 = $$v;
      },
      expression: "search2"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers2,
      "items": _vm.parts,
      "search": _vm.search2,
      "loading": _vm.loading2,
      "options": {
        sortBy: ['created'],
        sortDesc: [true]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.created`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")];
      }
    }], null, true)
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }