<template lang="html" src="./pageAdministrationSharingParts.template.vue"></template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageAdministrationSharingParts.i18n.json');

export default {
  name: 'PageAdministrationSharingParts',
  components: { },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      orders: [],
      parts: [],
      search: '',
      search2: '',
      headers: null,
      headers2: null,
      loading: true,
      loading2: true,
    };
  },
  created() {
    this.headers = [
      {
        text: this.$t('part'),
        filterable: true,
        value: 'part.name',
      },
      {
        text: this.$t('orderedBy'),
        filterable: true,
        value: 'brand.name',
      },
      {
        text: this.$t('quantity'),
        filterable: true,
        value: 'quantity',
      },
      {
        text: this.$t('orderStatus'),
        filterable: true,
        value: 'status',
      },
      {
        text: this.$t('created'),
        filterable: false,
        sortable: true,
        value: 'created',
      },
    ];
    this.headers2 = [
      {
        text: this.$t('part'),
        filterable: true,
        value: 'part.name',
        width: '40%'
      },
      {
        text: this.$t('sharedWith'),
        filterable: true,
        value: 'sharedWith.name',
        width: '40%'
      },
      {
        text: this.$t('sharedSince'),
        filterable: false,
        sortable: true,
        value: 'created',
      },
    ];
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.loading2 = true;
      this.getSharedPartsOrders();
      this.getBrandSharingParts();
    },
    getSharedPartsOrders() {
      this.$apiInstance.auditBrandSharedOrders(this.$appConfig.currentBrand)
        .then((data) => {
          this.orders = data;
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        })
        .finally(()=>{
          this.loading = false;
        });
    },
    getBrandSharingParts() {
      this.$apiInstance.getBrandSharingParts(this.$appConfig.currentBrand)
        .then((data) => {
          this.parts = data;
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        })
        .finally(()=>{
          this.loading2 = false;
        });
    },
  }
};
</script>
